(function () {
  let cityNames = $('.map-block__city-list>li')
  let cityDescrps = $('.map-block__description-item')
  let hint = $('.map-block__hint');
  let hintText = hint.html();
  let cityDescrpsHeight = cityDescrps.outerHeight();
  cityDescrps.css({maxHeight: cityDescrpsHeight});

  cityNames.each(function () {
    let name = $(this)
    name.on('click', function () {
      $('.map-block__city-list>li.active').removeClass('active')
      name.addClass('active');
      let itemDataName = name.attr('data-name')
      cityDescrps.removeClass('active');
      if ($('.map-block').hasClass('has-active-city')) {
        $('.map-block').removeClass('has-active-city');
      }
      // start WP filtering. Comment it if you work with project local---------------------------------------------------------------
      wpFilter(this)
      // end WP filtering
      setTimeout(function () {
        cityDescrps.find('span').text(itemDataName);
      }, 300)
      setTimeout(function () {
        cityDescrps.addClass('active');
        $('.map-block').addClass('has-active-city');
        cityDescrpsHeight = $('.map-block__description-item-wrapper').outerHeight();
        cityDescrps.css({maxHeight: cityDescrpsHeight});
        if ($(window).width() > 768) {
          hint.html('<h6>Continue Exploring!</h6><p>Check other cities.</p>')
          $('body').addClass('scroll-hidden');
        }
      }, 1200)
    })
  })

  // start WP filtering. Comment it if you work with project local------------------------------------------------------------
  let wpFilter = function (e) {
    // let citys = document.querySelectorAll('.map-block__map-wrapper li')
    // citys.forEach(function (city) {
    //   city.addEventListener('click', function () {
    //     let cityName = city.getAttribute('data-name')
    //     getCity(cityName);
    //   })
    // });
    let cityName = e.getAttribute('data-name')
    getCity(cityName);

    function getCity(cityName) {
      $.ajax({
        url: '/wp-admin/admin-ajax.php',
        type: 'POST',
        data: 'action=newCity&city=' + cityName,
        success: function (response) {
          var obj = jQuery.parseJSON(response);
          let descWrap = document.querySelector('.map-block__description-item-content')
          if (descWrap) {
            descWrap.innerHTML = obj.content1
          }
          let popUpWrap = document.querySelector('.map-popup')
          if (popUpWrap) {
            popUpWrap.innerHTML = obj.content2
          }
        }
      });
    }
  }
  // end WP filtering

  $(document).click(function (e) {
    if ($('.map-block').hasClass('has-active-city')) {
      if (!$(e.target).closest('.map-popup').length) {
        $('.map-block').removeClass('has-active-city');
        if ($(window).width() > 768) {
          hint.html(hintText)
          $('body').removeClass('scroll-hidden');
        }
      }
    }
  });

  $('.map-block__button').click(function () {
    $('.map-block').addClass('full-map')
    hint.html('<h6>Start Exploring!</h6><p>Drag map and Select a City</p>')
    $('.map-block__map-close').show();
    cityDescrpsHeight = $('.map-block__description-item-wrapper').outerHeight();
    cityDescrps.css({maxHeight: cityDescrpsHeight});
  })

  $('.map-block__map-close').click(function () {
    $('.map-block').removeClass('full-map');
    $('.map-block__map-close').hide();
  })

  $(document).on('click', '.map-popup__featured-button', function () {
    $('.map-popup__featured').toggleClass('view-changed')
  })

  $(document).on('click', '.map-popup__video-button', function (e) {
    let iframe = $(e.target).prev('iframe');
    let player = new Vimeo.Player(iframe);
    player.play();
    $(this).hide()
  })

})()

