(function () {
  $(".custom-datepicker").each(function () {
    const $this = $(this);
    const $wrapper = $this.parent();
    let isOpened = false;

    $this.datepicker({
      range: true,
      autoClose: true,
      language: "en",
      dateFormat: "d MM",
      multipleDatesSeparator: " - ",
      navTitles: {
        days: "M",
      },
      minDate: new Date(),
      onShow: function (inst, animationCompleted) {
        $wrapper.addClass("active");

        if (animationCompleted) {
          isOpened = true;
        }
      },
      onHide: function (inst, animationCompleted) {
        $wrapper.removeClass("active");

        if (animationCompleted) {
          isOpened = false;
        }
      }
    });

    $this.on("click", function () {
      if (isOpened) {
        $this.data("datepicker").hide();
      }
    });
  });
  $(".norange-datepicker").each(function () {
    const $this = $(this);
    const $wrapper = $this.parent();
    let isOpened = false;

    $this.datepicker({
      range: false,
      autoClose: true,
      language: "en",
      dateFormat: "d MM",
      multipleDatesSeparator: " - ",
      navTitles: {
        days: "M",
      },
      minDate: new Date(),
      onShow: function (inst, animationCompleted) {
        $wrapper.addClass("active");

        if (animationCompleted) {
          isOpened = true;
        }
      },
      onHide: function (inst, animationCompleted) {
        $wrapper.removeClass("active");

        if (animationCompleted) {
          isOpened = false;
        }
      }
    });

    $this.on("click", function () {
      if (isOpened) {
        $this.data("datepicker").hide();
      }
    });
  });

  $(".custom-book-form__select").each(function() {
    const $this = $(this);
    const $wrapper = $this.closest(".custom-dropdown-wrapper");

    $this.select2({
      minimumResultsForSearch: Infinity,
      width: "100%",
      theme: "custom",
      containerCssClass: "type-number"
    });

    $this.on("select2:opening", function() {
      $wrapper.addClass("active");
    });

    $this.on("select2:closing", function() {
      $wrapper.removeClass("active");
    });
  });
})();