// main
(function() {
  const $header = $('.page-header');
  const $menu = $('.menu-list');
  const $hamburger = $('.menu-icon');
  let isMenuOpened = false;

  $hamburger.on('click', function() {
    $(this).toggleClass('opened');
    $menu.toggleClass('opened');
    $header.toggleClass('menu-opened');
    console.log(isMenuOpened)
    isMenuOpened = !isMenuOpened;

    if (isMenuOpened) {
      bodyScrollLock.disableBodyScroll($menu[0]);
    } else {
      bodyScrollLock.clearAllBodyScrollLocks();
    }
  });

  $(window).on(
    'resize',
    debounce(function() {
      if (isMenuOpened && window.innerWidth >= 768) {
        bodyScrollLock.clearAllBodyScrollLocks();
      }
    })
  );

  function debounce(func, wait, immediate) {
    var timeout;
    return function() {
      var context = this,
        args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }
})();

// end main

(function() {
  const searchButton = document.querySelector('.search-item a')
  const searchBlock = document.querySelector('.search-block')
  let closeButton

  let toggleSearch = function(e) {
    e.preventDefault()
    if (!searchBlock.classList.contains('active')) {
      searchBlock.classList.add('active')
      closeButton = document.querySelector('.search-block .cls-btn')
      closeButton.addEventListener('click', closeSearch)
    } else {
      searchBlock.classList.remove('active')
    }
  }

  let closeSearch = function() {
    searchBlock.classList.remove('active')
  }

  searchButton.addEventListener('click', toggleSearch)

  let menuButtonToggle = function() {
    const links = document.querySelectorAll('.menu-item-has-children > a')
    if (window.innerWidth < 991) {
      links.forEach(function(link) {
        link.addEventListener('click', function(e) {
            e.preventDefault();
            let item = link.parentNode.querySelector('.sub-menu')
            if (item.classList.contains('active')) {
              item.classList.remove('active')
              return
            }
            let activeItem = document.querySelector('.sub-menu.active')
            if (activeItem) {
              activeItem.classList.remove('active')
            }
            item.classList.add('active')
        })
      })
    }
  }
  
  menuButtonToggle()
  // window.addEventListener('resize', menuButtonToggle)
})()