$(document).ready(() => {
    // Don't touch
    objectFitImages();
    svg4everybody();
    // End don't touch

    require('./menu.js');
    require('./target-blank.js');
    require('./slider.js');
    require('./popup.js');
    require('./map.js');
    require('./datepicker.js');
    require('./validation.js');
    require('./header.js');
    require('./city.js');
    require('./g-map.js')
});

// remove preloader
$(window).on('load', () => {
    $('.preloader').fadeOut();
    $('html').addClass('page-loaded');
    AOS.init({
        once: true,
        useClassNames: true,
        duration: 1000,
        delay: 200,
        // easing: 'linear',
        // easing: 'ease',
        // easing: 'ease-in',
        // easing: 'ease-out',
        // easing: 'ease-in-out',
        // easing: 'ease-in-back',
        // easing: 'ease-out-back',
        // easing: 'ease-in-out-back',
        // easing: 'ease-in-sine',
        // easing: 'ease-out-sine',
        // easing: 'ease-in-out-sine',
        // easing: 'ease-in-quad',
        // easing: 'ease-out-quad',
        // easing: 'ease-in-out-quad',
        // easing: 'ease-in-cubic',
        // easing: 'ease-out-cubic',
        // easing: 'ease-in-out-cubic',
        // easing: 'ease-in-quart',
        // easing: 'ease-out-quart',
        easing: 'ease-in-out-quart',

    });
});