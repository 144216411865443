$(document).ready(function () {

  let arrow = function(dir) {
    return `<div class='${dir}-button'><svg class='${dir}-arrow'><use xlink:href='${window.location.origin}/wp-content/themes/visit-placer/svg-sprite/sprite.svg#peaks-arrow'></use></svg></div>`
  }

  $('.slider').slick({
    centerMode: true,
    slidesToShow: 3,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 7000,
    prevArrow: arrow('prev'),
    nextArrow: arrow('next'),
    responsive: [{
      breakpoint: 767,
      settings: {
        arrows: false,
        centerMode: true,
        slidesToShow: 1
      }
    }]
  });

  $('.ambassador-slider').slick({
    slidesToShow: 4,
    infinite: true,
    speed: 500,
    autoplaySpeed: 7000,
    prevArrow: arrow('prev'),
    nextArrow: arrow('next'),
    responsive: [{
      breakpoint: 1200,
      settings: {
        arrows: false,
        slidesToShow: 3,
      }
    }, {
      breakpoint: 992,
      settings: {
        arrows: false,
        slidesToShow: 2,
        dots: true
      }
    }, {
      breakpoint: 565,
      settings: {
        arrows: false,
        slidesToShow: 1,
        dots: true
      }
    }]
  });

  $('.slider-place').slick({
    slidesToShow: 1,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 7000,
    fade: true,
    cssEase: 'linear',
    prevArrow: arrow('prev'),
    nextArrow: arrow('next'),
    responsive: [{
      breakpoint: 767,
      settings: {
        arrows: false,
        dots: true,
      }
    }]
  });
});

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};