(function () {
  if (!!$('.hero--city').length) {
    let iframe = $('.hero--city').find('iframe');
    let player = new Vimeo.Player(iframe);

    if ($(window).width() > 768) {
      player.play()
    } else {
      $('.city__video-button').click(function () {
        player.play();
        $(this).hide()
      })
    }
  }
})()