(function () {
  const googleMapEle = document.getElementById('google-map');

  if (!googleMapEle) return;

  const mainPos = { lat: +googleMapEle.getAttribute('data-pin-lat'), lng: +googleMapEle.getAttribute('data-pin-lng') };
  const mainLogo = {
    url: googleMapEle.getAttribute('data-pin-src'),
    scaledSize: new google.maps.Size(50, 50)
  }

  const mainTitle = googleMapEle.getAttribute('data-pin-title');
  let zoom;
  if (window.innerWidth > 568) {
    zoom = +googleMapEle.getAttribute('data-zoom');
  } else {
    zoom = 8;
  }
  const centerLat = +googleMapEle.getAttribute('data-center-lat');
  const centerLng = +googleMapEle.getAttribute('data-center-lng')

  function initMap() {
    const map = new google.maps.Map(googleMapEle, {
      zoom: zoom,
      scrollwheel: false,
      center: { lat: centerLat, lng: centerLng },
      disableDefaultUI: true,
      styles: [
        {
          "featureType": "administrative.land_parcel",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "landscape.man_made",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "simplified"
            },
            {
              "lightness": 20
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry",
          "stylers": [
            {
              "hue": "#f49935"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "simplified"
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "geometry",
          "stylers": [
            {
              "hue": "#fad959"
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "geometry",
          "stylers": [
            {
              "visibility": "simplified"
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "simplified"
            }
          ]
        },
        {
          "featureType": "transit",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "all",
          "stylers": [
            {
              "hue": "#a1cdfc"
            },
            {
              "saturation": 30
            },
            {
              "lightness": 49
            }
          ]
        }
      ]
    });

    var placer = [
      { lng: -120.0023, lat: 39.0677 },
      { lng: -120.1422, lat: 39.068 },
      { lng: -120.1427, lat: 39.0612 },
      { lng: -120.154, lat: 39.0616 },
      { lng: -120.1545, lat: 39.0475 },
      { lng: -120.1634, lat: 39.047 },
      { lng: -120.1633, lat: 39.0384 },
      { lng: -120.1817, lat: 39.0388 },
      { lng: -120.1822, lat: 39.0324 },
      { lng: -120.2385, lat: 39.0321 },
      { lng: -120.2396, lat: 39.0235 },
      { lng: -120.2579, lat: 39.0256 },
      { lng: -120.268, lat: 39.0238 },
      { lng: -120.3468, lat: 39.0237 },
      { lng: -120.4357, lat: 39.0285 },
      { lng: -120.4468, lat: 39.0166 },
      { lng: -120.4455, lat: 39.0103 },
      { lng: -120.4536, lat: 38.9957 },
      { lng: -120.4529, lat: 38.9871 },
      { lng: -120.4582, lat: 38.9848 },
      { lng: -120.4587, lat: 38.978 },
      { lng: -120.4692, lat: 38.9652 },
      { lng: -120.478, lat: 38.9602 },
      { lng: -120.4845, lat: 38.9592 },
      { lng: -120.485, lat: 38.9561 },
      { lng: -120.4921, lat: 38.9501 },
      { lng: -120.4943, lat: 38.9446 },
      { lng: -120.5026, lat: 38.9405 },
      { lng: -120.5061, lat: 38.9369 },
      { lng: -120.5219, lat: 38.929 },
      { lng: -120.5279, lat: 38.9303 },
      { lng: -120.5367, lat: 38.9239 },
      { lng: -120.542, lat: 38.9252 },
      { lng: -120.5555, lat: 38.9156 },
      { lng: -120.5643, lat: 38.9123 },
      { lng: -120.5708, lat: 38.9127 },
      { lng: -120.5809, lat: 38.9185 },
      { lng: -120.587, lat: 38.9267 },
      { lng: -120.587, lat: 38.9294 },
      { lng: -120.5954, lat: 38.9361 },
      { lng: -120.606, lat: 38.9342 },
      { lng: -120.6127, lat: 38.9432 },
      { lng: -120.6215, lat: 38.9404 },
      { lng: -120.6251, lat: 38.9422 },
      { lng: -120.6269, lat: 38.9471 },
      { lng: -120.6322, lat: 38.9435 },
      { lng: -120.6494, lat: 38.9474 },
      { lng: -120.6536, lat: 38.9501 },
      { lng: -120.6548, lat: 38.9537 },
      { lng: -120.6762, lat: 38.9603 },
      { lng: -120.6829, lat: 38.9688 },
      { lng: -120.6826, lat: 38.9897 },
      { lng: -120.7026, lat: 38.9813 },
      { lng: -120.7127, lat: 38.9826 },
      { lng: -120.7211, lat: 38.9888 },
      { lng: -120.7236, lat: 38.9965 },
      { lng: -120.726, lat: 38.9992 },
      { lng: -120.7314, lat: 39.0028 },
      { lng: -120.7414, lat: 39.0013 },
      { lng: -120.7498, lat: 39.0076 },
      { lng: -120.7663, lat: 39.0034 },
      { lng: -120.77, lat: 39.0079 },
      { lng: -120.7982, lat: 38.9958 },
      { lng: -120.806, lat: 39.0016 },
      { lng: -120.8283, lat: 38.9896 },
      { lng: -120.8298, lat: 38.9773 },
      { lng: -120.8315, lat: 38.9732 },
      { lng: -120.8356, lat: 38.9705 },
      { lng: -120.8481, lat: 38.9753 },
      { lng: -120.8546, lat: 38.9702 },
      { lng: -120.8514, lat: 38.9626 },
      { lng: -120.8572, lat: 38.9534 },
      { lng: -120.8625, lat: 38.9516 },
      { lng: -120.8893, lat: 38.9585 },
      { lng: -120.9039, lat: 38.952 },
      { lng: -120.9284, lat: 38.9613 },
      { lng: -120.9361, lat: 38.9625 },
      { lng: -120.9379, lat: 38.9621 },
      { lng: -120.9387, lat: 38.9444 },
      { lng: -120.9368, lat: 38.9394 },
      { lng: -120.9409, lat: 38.9348 },
      { lng: -120.9563, lat: 38.9378 },
      { lng: -120.9668, lat: 38.9313 },
      { lng: -120.9898, lat: 38.9256 },
      { lng: -120.9956, lat: 38.9197 },
      { lng: -121.0215, lat: 38.9162 },
      { lng: -121.0238, lat: 38.9134 },
      { lng: -121.0274, lat: 38.9129 },
      { lng: -121.0375, lat: 38.9155 },
      { lng: -121.0444, lat: 38.9059 },
      { lng: -121.0537, lat: 38.899 },
      { lng: -121.0446, lat: 38.8901 },
      { lng: -121.0604, lat: 38.8822 },
      { lng: -121.0555, lat: 38.8709 },
      { lng: -121.0553, lat: 38.8636 },
      { lng: -121.0594, lat: 38.86 },
      { lng: -121.0563, lat: 38.8532 },
      { lng: -121.0567, lat: 38.8487 },
      { lng: -121.0591, lat: 38.8459 },
      { lng: -121.0738, lat: 38.8426 },
      { lng: -121.0878, lat: 38.8338 },
      { lng: -121.0888, lat: 38.8288 },
      { lng: -121.0828, lat: 38.822 },
      { lng: -121.0844, lat: 38.8179 },
      { lng: -121.0927, lat: 38.8147 },
      { lng: -121.1004, lat: 38.8186 },
      { lng: -121.1051, lat: 38.8186 },
      { lng: -121.1068, lat: 38.8158 },
      { lng: -121.0984, lat: 38.8096 },
      { lng: -121.0984, lat: 38.8055 },
      { lng: -121.1041, lat: 38.7982 },
      { lng: -121.1033, lat: 38.79 },
      { lng: -121.1084, lat: 38.7795 },
      { lng: -121.1189, lat: 38.7713 },
      { lng: -121.1223, lat: 38.7644 },
      { lng: -121.1232, lat: 38.7544 },
      { lng: -121.1307, lat: 38.7475 },
      { lng: -121.1335, lat: 38.7393 },
      { lng: -121.148, lat: 38.7301 },
      { lng: -121.1436, lat: 38.717 },
      { lng: -121.1447, lat: 38.712 },
      { lng: -121.485, lat: 38.7346 },
      { lng: -121.4849, lat: 38.751 },
      { lng: -121.4701, lat: 38.7512 },
      { lng: -121.4684, lat: 38.9258 },
      { lng: -121.4139, lat: 38.9266 },
      { lng: -121.4141, lat: 39.0014 },
      { lng: -121.3872, lat: 39.0127 },
      { lng: -121.3704, lat: 39.0266 },
      { lng: -121.3392, lat: 39.0365 },
      { lng: -121.3181, lat: 39.0455 },
      { lng: -121.3034, lat: 39.0493 },
      { lng: -121.295, lat: 39.0435 },
      { lng: -121.268, lat: 39.0308 },
      { lng: -121.2441, lat: 39.022 },
      { lng: -121.2323, lat: 39.0236 },
      { lng: -121.2262, lat: 39.0191 },
      { lng: -121.2213, lat: 39.0124 },
      { lng: -121.2095, lat: 39.0117 },
      { lng: -121.2031, lat: 39.0158 },
      { lng: -121.1942, lat: 39.0155 },
      { lng: -121.1876, lat: 39.0124 },
      { lng: -121.1771, lat: 39.0189 },
      { lng: -121.1682, lat: 39.0195 },
      { lng: -121.1612, lat: 39.0245 },
      { lng: -121.1541, lat: 39.0255 },
      { lng: -121.1472, lat: 39.0324 },
      { lng: -121.1442, lat: 39.0311 },
      { lng: -121.1395, lat: 39.0353 },
      { lng: -121.133, lat: 39.0353 },
      { lng: -121.1252, lat: 39.0314 },
      { lng: -121.1204, lat: 39.0264 },
      { lng: -121.1184, lat: 39.0192 },
      { lng: -121.1054, lat: 39.0171 },
      { lng: -121.0987, lat: 39.0109 },
      { lng: -121.0899, lat: 39.0137 },
      { lng: -121.0755, lat: 39.0062 },
      { lng: -121.0696, lat: 39.0053 },
      { lng: -121.0402, lat: 39.0148 },
      { lng: -121.0309, lat: 39.0249 },
      { lng: -121.0282, lat: 39.0376 },
      { lng: -121.0212, lat: 39.0404 },
      { lng: -121.0195, lat: 39.0449 },
      { lng: -121.0142, lat: 39.0468 },
      { lng: -121.0089, lat: 39.0519 },
      { lng: -121.0044, lat: 39.0614 },
      { lng: -120.9974, lat: 39.0674 },
      { lng: -120.9975, lat: 39.0724 },
      { lng: -120.9935, lat: 39.0788 },
      { lng: -120.9879, lat: 39.0947 },
      { lng: -120.9882, lat: 39.111 },
      { lng: -120.9829, lat: 39.1093 },
      { lng: -120.9672, lat: 39.1272 },
      { lng: -120.9508, lat: 39.1373 },
      { lng: -120.9479, lat: 39.1423 },
      { lng: -120.9368, lat: 39.1493 },
      { lng: -120.9363, lat: 39.1556 },
      { lng: -120.9263, lat: 39.1562 },
      { lng: -120.9234, lat: 39.1626 },
      { lng: -120.9075, lat: 39.1659 },
      { lng: -120.9081, lat: 39.17 },
      { lng: -120.9041, lat: 39.1759 },
      { lng: -120.8875, lat: 39.177 },
      { lng: -120.8668, lat: 39.1832 },
      { lng: -120.8582, lat: 39.195 },
      { lng: -120.8531, lat: 39.2105 },
      { lng: -120.8401, lat: 39.2152 },
      { lng: -120.8202, lat: 39.2281 },
      { lng: -120.8019, lat: 39.2351 },
      { lng: -120.7784, lat: 39.2539 },
      { lng: -120.7713, lat: 39.2558 },
      { lng: -120.7626, lat: 39.2636 },
      { lng: -120.7572, lat: 39.265 },
      { lng: -120.7456, lat: 39.2765 },
      { lng: -120.7309, lat: 39.2866 },
      { lng: -120.7161, lat: 39.2913 },
      { lng: -120.7049, lat: 39.2977 },
      { lng: -120.6724, lat: 39.3098 },
      { lng: -120.654, lat: 39.3104 },
      { lng: -120.6457, lat: 39.315 },
      { lng: -120.3978, lat: 39.3166 },
      { lng: -120.0047, lat: 39.3161 },
      { lng: -120.0023, lat: 39.0677 }
    ];

    var placerPolygon = new google.maps.Polygon({
      paths: placer,
      strokeColor: '#ff0000',
      strokeOpacity: 0.4,
      strokeWeight: 2,
      fillColor: '#ffffff',
      fillOpacity: 0.3,
    });

    placerPolygon.setMap(map);

    const marker = new google.maps.Marker({
      position: mainPos,
      map,
      title: mainTitle,
      icon: mainLogo
    });

    const Popup = createPopupClass();

    $('.map__popup').each(function () {
      const type = $(this).data('map-type');
      const lat = $(this).data('map-lat');
      const lng = $(this).data('map-lng');

      const popup = new Popup(
        new google.maps.LatLng(lat, lng),
        this,
        `map__${type}`
      );
      popup.setMap(map);
    });

    let controlDiv = document.createElement('div');
    let zoomControl = new MapZoomControl(controlDiv, map);
    map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(controlDiv);

    function MapZoomControl(controlDiv, map) {
      var controlUI = document.createElement('div');
      controlUI.className = 'map__custom-zoom';
      controlDiv.appendChild(controlUI);

      let controlZoomIn = document.createElement('div');
      controlZoomIn.className = 'map__custom-zoom-in';
      controlUI.appendChild(controlZoomIn);

      let zoomText = document.createElement('span')
      zoomText.textContent = 'zoom in/out'
      controlUI.appendChild(zoomText);

      let controlZoomOut = document.createElement('div');
      controlZoomOut.className = 'map__custom-zoom-out'
      controlUI.appendChild(controlZoomOut);

      controlZoomIn.addEventListener('click', function () {
        map.setZoom(map.getZoom() + 1);
      });
      controlZoomOut.addEventListener('click', function () {
        map.setZoom(map.getZoom() - 1);
      });
    }

    $(document).on('click', '.map-header li', function () {
      let type = $(this).attr('data-type');
      if (type == "all") {
        $(this).removeClass('disabled').siblings().removeClass('disabled');
        $('.map__marker').each(function () {
          $(this).removeClass('disabled');
        })
        map.setZoom(zoom);
      } else {
        $(this).removeClass('disabled').siblings().addClass('disabled');
        $('.map__marker').each(function () {
          if ($(this).attr('data-location-type') == type) {
            $(this).removeClass('disabled');
          } else {
            $(this).addClass('disabled');
          }
        })
        map.setZoom(+$(this).attr('data-zoom'));
      }
    })
  }



  /**
   * Returns the Popup class.
   *
   * Unfortunately, the Popup class can only be defined after
   * google.maps.OverlayView is defined, when the Maps API is loaded.
   * This function should be called by initMap.
   */
  function createPopupClass() {
    /**
     * A customized popup on the map.
     * @param {!google.maps.LatLng} position
     * @param {!Element} content The bubble div.
     * @constructor
     * @extends {google.maps.OverlayView}
     */
    function Popup(position, content, className) {
      this.position = position;

      content.classList.add(className);

      // This zero-height div is positioned at the bottom of the bubble.
      var bubbleAnchor = document.createElement('div');
      bubbleAnchor.classList.add(className + '-anchor');
      bubbleAnchor.appendChild(content);

      // This zero-height div is positioned at the bottom of the tip.
      this.containerDiv = document.createElement('div');
      this.containerDiv.classList.add(className + '-container');
      this.containerDiv.appendChild(bubbleAnchor);

      // Optionally stop clicks, etc., from bubbling up to the map.
      google.maps.OverlayView.preventMapHitsAndGesturesFrom(this.containerDiv);
    }

    // ES5 magic to extend google.maps.OverlayView.
    Popup.prototype = Object.create(google.maps.OverlayView.prototype);

    /** Called when the popup is added to the map. */
    Popup.prototype.onAdd = function () {
      this.getPanes().floatPane.appendChild(this.containerDiv);
    };

    /** Called when the popup is removed from the map. */
    Popup.prototype.onRemove = function () {
      if (this.containerDiv.parentElement) {
        this.containerDiv.parentElement.removeChild(this.containerDiv);
      }
    };

    /** Called each frame when the popup needs to draw itself. */
    Popup.prototype.draw = function () {
      var divPosition = this.getProjection().fromLatLngToDivPixel(
        this.position
      );

      // Hide the popup when it is far out of view.
      var display =
        Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
          ? 'block'
          : 'none';

      if (display === 'block') {
        this.containerDiv.style.left = divPosition.x + 'px';
        this.containerDiv.style.top = divPosition.y + 'px';
      }
      if (this.containerDiv.style.display !== display) {
        this.containerDiv.style.display = display;
      }
    };

    return Popup;
  }

  initMap();

  $(document).on('touchstart', '.map__marker', function (e) {
    let target = e.target
    if (e.target.closest('svg')) {
      let fromTop = target.getBoundingClientRect()
      let height = window.innerHeight
      let parent = target.closest('.map__marker')
      let next = parent.querySelector('.map__info')
      if (fromTop.top > (height / 2)) {
        next.classList.add('toTop')
      } else {
        next.classList.remove('toTop')
      }
    }
  })

  let popups = document.querySelectorAll('.map__info')

  document.addEventListener('click', function (e) {
    let target = e.target
    if (target.closest('.g-filter-item')) {
      let filterItem = target.closest('.g-filter-item')
      let filterAtt = filterItem.getAttribute('data-type')
      var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?filter=' + filterAtt + '#google-map'
      window.history.pushState({ path: newurl }, '', newurl);
    }

    let popupActive = document.querySelector('.map__info.active')
    if (target.closest('.map__marker')) {
      let marker = target.closest('.map__marker')
      let markerAtt = marker.getAttribute('data-map-popup')
      if (popupActive) {
        popupActive.classList.remove('active')
      }
      popups.forEach(function (popup) {
        if (popup.getAttribute('data-map-popup') == markerAtt) {
          popup.classList.add('active')
          let name = popup.querySelector('H5').textContent.replace(/[\n\r]+|[\s]{2,}/g, ' ').trim()
          ga('send', 'event', 'Map Place', 'Click', name, 1);
        }
      })
    } else if (!target.closest('.map__marker') && !target.closest('.map__info')) {
      if (popupActive) {
        popupActive.classList.remove('active')
      }
    }
  })

  let filterOnLoad = function () {
    let curUrl = window.location.href;
    let url = new URL(curUrl);
    let filterParam = url.searchParams.get("filter");
    if (filterParam) {
      let activeFilter = document.querySelector(`[data-type="${filterParam}"]`)
      activeFilter.click()
    }
  }

  window.addEventListener('load', function () {
    filterOnLoad()
  });
})
  ();
